<template>
    <b-container fluid class="b-container-no-padding">
        <CommonTabsComponent
            :tabList="tabList"
            @tabChanged="tabChanged"
        ></CommonTabsComponent>
        <component v-bind:is="componentName" />
    </b-container>
</template>

<script>
export default {
    name: 'Map3DWrapper',
    components: {
        CommonTabsComponent: () => import('../CommonTabsComponent.vue'),
        'map3d-sidebar': () => import('./Map3DSidebar.vue'),
    },
    data() {
        return {
            selectedTabName: 'Upload 3D Visualization',
            tabList: [
                { name: 'Upload 3D Visualization', component: 'map3d-sidebar' },
            ],
        };
    },
    methods: {
        tabChanged(newTab, prevTab) {
            this.selectedTabName = newTab.name;
        },
    },
    computed: {
        componentName() {
            return this.tabList.find((tab) => tab.name === this.selectedTabName)
                .component;
        },
    },
};
</script>

<style scoped>
.b-container-no-padding {
    padding: 0px;
}
</style>
